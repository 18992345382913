import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../components/layout'
import ExpansionPanel from '../components/expansion-panel'
import ListLink from '../mui-components/ListLink/ListLink'
import SEO from '../components/seo'

export const query = graphql`
  query TypesOfSanctionsQuery {
    categories: allSanitySanctionCategory(sort: { fields: order }) {
      nodes {
        _key
        id
        title
        icon {
          _key
          iconSource
        }
      }
    }
    typesOfContext: allSanityTypeOfContext(sort: { fields: order }) {
      nodes {
        _key
        id
        title
      }
    }
    sanctionTypes: allSanitySanctionType(sort: { fields: order }) {
      nodes {
        _key
        id
        title
        path
        category {
          _type
          _key
          id
          title
          icon {
            _key
            iconSource
          }
        }
        contextType {
          _key
          _type
          id
          title
        }
        parentType {
          _key
          id
          title
          impact {
            title
            icon {
              iconSource
            }
          }
        }
        impact {
          title
          icon {
            iconSource
          }
        }
      }
    }
  }
`

const TypesOfSanctionsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const sanctionTypesHierarchy = (type_id, category_id) => {
    const customizedNodes = []

    // First get all sanction types for this context & category
    data.sanctionTypes.nodes.forEach(node => {
      if (
        node.contextType &&
        node.contextType.id === type_id &&
        node.category &&
        node.category.id === category_id
      ) {
        // We need to have new unique property we can group items by (parent ID in this case)
        const customNode = { ...node }
        customNode.parentTypeID = node.parentType ? node.parentType.id : 0

        customizedNodes.push(customNode)
      }
    })

    // Function source: https://hackernoon.com/you-might-not-need-that-recursive-function-in-javascript-275651522185
    // Create root for top-level node(s)
    const hierarchicalNodes = []
    const map = {}
    customizedNodes.forEach(node => {
      // No parentId means top level
      if (!node.parentTypeID) return hierarchicalNodes.push(node)

      // Get index of item where children would be inserted
      let parentIndex = map[node.parentTypeID]
      if (typeof parentIndex !== 'number') {
        parentIndex = customizedNodes.findIndex(el => el.id === node.parentTypeID)
        map[node.parentTypeID] = parentIndex
      }

      // Check if we really have parent in array, otherwise push item as top level
      if (parentIndex === -1) {
        return hierarchicalNodes.push(node)
      }

      // Insert node as child of parent in flat array
      if (!customizedNodes[parentIndex].childTypes) {
        return (customizedNodes[parentIndex].childTypes = [node])
      }

      customizedNodes[parentIndex].childTypes.push(node)
    })

    return hierarchicalNodes
  }

  // Recursive function to get all items and their children
  const sanctionTypesItems = nodes => {
    const items = []

    nodes.forEach(node => {
      // Check, if current parent item does have any children items in our grouped object
      // We need to wrap children into Expansion panel
      if (node.childTypes) {
        const subitems = sanctionTypesItems(node.childTypes)

        // Wrap children items into Expansion panel
        items.push(
          <ExpansionPanel
            title={node.title}
            key={node.id}
            id={'tos-st-' + node.id}
            rememberExpanded
            icon={node.impact ? node.impact.icon : null}
            type="target"
            iconNoBg
            location={props.location}
          >
            {subitems}
          </ExpansionPanel>
        )
      } else {
        // If we dont have parents, output items as regular list links
        items.push(
          <ListLink
            title={node.title}
            to={node.path}
            key={node.id}
            icon={node.impact ? node.impact.icon : null}
            type="target"
          />
        )
      }
    })

    return items
  }

  // Get Sanction types from specified Type of context and Category
  const sanctionTypes = (type_id, category_id) => {
    const nodes = sanctionTypesHierarchy(type_id, category_id)
    const items = sanctionTypesItems(nodes)

    return items
  }

  // Get Types of context and display Sanction types as children
  const typesOfContext = category_id => {
    const items = []

    data.typesOfContext.nodes.forEach(node => {
      const types = sanctionTypes(node.id, category_id)
      const isDefaultExpanded = !!node.title.includes('Mandatory')

      if (types.length > 0) {
        items.push(
          <ExpansionPanel
            title={node.title}
            key={node.id}
            id={'tos-context-' + node.id + category_id}
            rememberExpanded
            location={props.location}
            isDefaultExpanded={isDefaultExpanded}
          >
            {types}
          </ExpansionPanel>
        )
      }
    })

    return items
  }

  // Get Categories and display Types of context and Sanction Types as children
  const categories = data.categories.nodes.map(node => {
    const svgIcon = node.icon
    const items = typesOfContext(node.id)

    return items.length > 0 ? (
      <ExpansionPanel
        title={node.title}
        key={node.id}
        id={'tos-cat-' + node.id}
        rememberExpanded
        icon={svgIcon}
        nested
        location={props.location}
      >
        {items}
      </ExpansionPanel>
    ) : null
  })

  const title = 'Types of sanctions'

  return (
    <Layout title={title} {...props}>
      <SEO title={title} />
      {categories}
    </Layout>
  )
}

export default TypesOfSanctionsPage
